




import Vue from "vue";
import HoursWithdrawal from "@/components/HoursWithdrawal.vue";

export default Vue.extend({
  components: {
    HoursWithdrawal,
  },
});
