




































import Vue from "vue";
import CenterColumnWrapper from "@/components/CenterColumnWrapper.vue";
import { EntriesSummarizedPerMonthPerTask } from "@/store/timeEntries";
import InvoiceStatistics from "../components/InvoiceStatistics.vue";

export default Vue.extend({
  name: "Summarizedhours",
  components: {
    CenterColumnWrapper,
    InvoiceStatistics,
  },
  computed: {
    getTimeEntriesSummed(): EntriesSummarizedPerMonthPerTask {
      return this.$store.getters.getTimeEntriesSummarizedPerMonthPerTask;
    },

    getLastThreeMonths() {
      return this.$store.getters.getLastThreeMonthsForStatistics;
    },
  },

  beforeCreate() {
    this.$store.commit("CREATE_WEEKS");
    this.$store.dispatch("FETCH_WEEK_ENTRIES");
  },
});
