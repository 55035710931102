






import Vue from "vue";
export default Vue.extend({
  props: {
    text: {
      type: String,
      default: "",
    },
  },

  computed: {
    calculatedText() {
      // @ts-ignore
      return this.$mq !== "sm" ? this.text : "";
    },
  },
});
