








import Vue from "vue";
import Tooltip from "@/components/Tooltip.vue";

export default Vue.extend({
  components: {
    Tooltip,
  },

  props: {
    toggle: Boolean,
    iconId: {
      type: String,
      default: "",
    },
    iconId2: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
    tooltip: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    iconIdToShow(): string {
      if (this.iconId && !this.iconId2) return this.iconId;
      return this.toggle ? this.iconId : this.iconId2;
    },
  },

  methods: {
    onClick() {
      this.$emit("click");
    },
  },
});
