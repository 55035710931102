












import Vue from "vue";
import YellowButton from "@/components/YellowButton.vue";

export default Vue.extend({
  components: {
    YellowButton,
  },

  computed: {
    show() {
      return (
        // @ts-ignore
        this.$mq === "sm" &&
        this.$store.state.activeTaskId === -1 &&
        this.$store.state.currentRoute.name === "hours" &&
        !this.$store.state.drawerOpen
      );
    },
  },

  methods: {
    onTodayClick() {
      this.$store.commit("SLIDE_TO_TODAY");
    },
  },
});
