






















































import Vue from "vue";
import InvoiceChart from "./InvoiceChart.vue";
import SimpleDatePicker from "./SimpleDatePicker.vue";
import {
  InvoiceStatisticsFilters,
  InvoiceStatisticsPreset,
  InvoiceStatisticsPresetTypes,
  InvoicePeriods,
} from "../store/invoiceRate";
import InvoiceStatisticCard from "./InvoiceStatisticCard.vue";
import { createTimeString } from "@/utils/timestamp-text-util";

const invoiceStatisticPresets: InvoiceStatisticsPreset[] = [
  {
    type: InvoiceStatisticsPresetTypes.YEAR_INTERVAL,
    label: "Siste år",
    granularity: InvoicePeriods.Monthly,
  },
  {
    type: InvoiceStatisticsPresetTypes.HALF_YEAR_INTERVAL,
    label: "Siste halvår",
    granularity: InvoicePeriods.Monthly,
  },
  {
    type: InvoiceStatisticsPresetTypes.QUARTER_INTERVAL,
    label: "Siste kvartal",
    granularity: InvoicePeriods.Weekly,
  },
  {
    type: InvoiceStatisticsPresetTypes.WEEK_INTERVAL,
    label: "Siste uke",
    granularity: InvoicePeriods.Daily,
  },
];

const granularityOptions = [
  {
    value: InvoicePeriods.Annualy,
    label: "År",
  },
  {
    value: InvoicePeriods.Monthly,
    label: "Måned",
  },
  {
    value: InvoicePeriods.Weekly,
    label: "Uke",
  },
  {
    value: InvoicePeriods.Daily,
    label: "Dag",
  },
];

export default Vue.extend({
  components: { InvoiceChart, SimpleDatePicker, InvoiceStatisticCard },
  data() {
    const filters = this.$store.getters
      .getInvoiceFilters as InvoiceStatisticsFilters;
    return {
      initialFromDate: filters.fromDate,
      initialToDate: filters.toDate,
      invoiceStatisticPresets,
      granularityOptions,
      selectedGranularity: filters.granularity,
      summarizedStatistics: [],
      unsubscribe: () => {},
    };
  },
  created() {
    this.$store.subscribe(mutation => {
      if (mutation.type === "SET_INVOICE_STATISTIC") {
        this.summarizedStatistics = this.$store.getters.getSummarizedStatistics;
      } else if (mutation.type === "SET_INVOICE_STATISTIC_FILTERS") {
        const {
          granularity,
          fromDate,
          toDate,
        } = this.$store.getters.getInvoiceFilters;
        this.selectedGranularity = granularity;
        this.initialFromDate = fromDate;
        this.initialToDate = toDate;
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  methods: {
    setToDate(event: string) {
      this.$store.dispatch("CHANGE_INVOICE_FILTERS", { toDate: event });
    },
    setFromDate(event: string) {
      this.$store.dispatch("CHANGE_INVOICE_FILTERS", { fromDate: event });
    },
    setInterval(event: {
      fromDate: string;
      toDate: string;
      granularity?: InvoicePeriods;
    }) {
      const { fromDate, toDate, granularity } = event;
      this.$store.dispatch("CHANGE_INVOICE_FILTERS", {
        fromDate,
        toDate,
        granularity,
      });
    },
    setGranularity(event: any) {
      const granularity: InvoicePeriods = +event.target.value;
      const currentFilters: InvoiceStatisticsFilters = this.$store.getters
        .getInvoiceFilters;
      this.$store.dispatch("CHANGE_INVOICE_FILTERS", {
        ...currentFilters,
        granularity,
      });
    },
    setDateFiltersFromPreset(
      type: InvoiceStatisticsPresetTypes,
      granularity: InvoicePeriods
    ) {
      const toDate = new Date();
      let fromDate = new Date(toDate.getTime());

      const toMonth = toDate.getMonth() + 1; // 0-indexed, so have to add one
      const toYear = toDate.getFullYear();
      switch (type) {
        case InvoiceStatisticsPresetTypes.YEAR_INTERVAL:
          fromDate.setMonth(toMonth);
          fromDate.setFullYear(toYear - 1);
          break;
        case InvoiceStatisticsPresetTypes.HALF_YEAR_INTERVAL:
          fromDate.setMonth(toMonth - 6);
          break;
        case InvoiceStatisticsPresetTypes.QUARTER_INTERVAL:
          fromDate.setMonth(toMonth - 3);
          break;
        case InvoiceStatisticsPresetTypes.WEEK_INTERVAL: {
          const toDay = toDate.getDate();
          fromDate.setMonth(toMonth);
          fromDate.setDate(toDay - 7);
          break;
        }
        default:
          console.warn(`Unknown preset type: ${type}`);
          return;
      }

      const formattedFromDate = createTimeString(
        fromDate.getFullYear(),
        fromDate.getMonth(),
        fromDate.getDate()
      );
      const formattedToDate = createTimeString(
        toYear,
        toMonth,
        toDate.getDate()
      );

      this.setInterval({
        fromDate: formattedFromDate,
        toDate: formattedToDate,
        granularity,
      });
    },
  },
});
