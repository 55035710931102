















import Vue from "vue";
import { SummarizedStatistics } from "../store/invoiceRate";
export default Vue.extend({
  props: {
    cardData: {
      type: Object as () => SummarizedStatistics,
      default: {
        title: "",
        values: [],
      },
    },
  },
});
