













import Vue from "vue";
export default Vue.extend({
  props: {
    defaultDate: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      currentDate: this.defaultDate || "",
    };
  },
  watch: {
    defaultDate() {
      this.currentDate = this.defaultDate;
    },
  },
  methods: {
    onDateChange(event: InputEvent) {
      const target = event?.target as HTMLInputElement;
      if (!target.value) {
        target.value = this.defaultDate;
      }

      if (this.currentDate === target.value) {
        return;
      }

      this.currentDate = target.value;
      this.$emit("dateSelected", this.currentDate);
    },
  },
});
