



































import Input from "./Input.vue";
import Tooltip from "./Tooltip.vue";
import Vue from "vue";
import YellowButton from "./YellowButton.vue";
import config from "@/config";
import httpClient from "../services/httpClient";
import moment from "moment";

export default Vue.extend({
  components: {
    YellowButton,
    Tooltip,
    Input,
  },

  data() {
    return {
      token: "",
      expiryDate: "",
      friendlyName: "",
    };
  },

  computed: {
    buttonText(): string {
      // @ts-ignore
      return this.$mq === "sm" ? "" : "Lag nytt token";
    },

    showToken(): boolean {
      return !!this.token;
    },
  },

  methods: {
    onGenereateTokenClick() {
      this.fetchAccessToken();
    },

    onCopyClick() {
      const text = this.token.split(" ")[0];
      this.$copyText(text);
    },

    async fetchAccessToken() {
      httpClient
        .post<{ token: string; expiryDate: string }>(
          `${config.API_HOST}/api/user/AccessToken`,
          { friendlyName: this.friendlyName }
        )
        .then(response => {
          this.token = response.data.token;
          this.expiryDate = moment(response.data.expiryDate).format(
            "dddd D. MMMM YYYY"
          );
        })
        .catch(e => {
          console.error(e);
          this.$store.commit("ADD_TO_ERROR_LIST", e);
        });
    },
  },
});
