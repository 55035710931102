




import Vue from "vue";
import UnAutherizedUser from "@/components/UnAutherizedUser.vue";

export default Vue.extend({
  components: {
    UnAutherizedUser,
  },
});
