













































import Vue from "vue";
import moment from "moment";
import ErrorSnackbar from "@/components/ErrorSnackbar.vue";
import UpdateSnackbar from "@/components/UpdateSnackbar.vue";
import OnlineSnackbar from "@/components/OnlineSnackbar.vue";
import Toolbar from "@/components/Toolbar.vue";
import Hamburger from "@/components/Hamburger.vue";
import Drawer from "@/components/Drawer.vue";
import DayFooter from "@/components/DayFooter.vue";
import CenterColumnWrapper from "@/components/CenterColumnWrapper.vue";
import InvoiceRate from "@/components/InvoiceRate.vue";

export default Vue.extend({
  components: {
    ErrorSnackbar,
    UpdateSnackbar,
    OnlineSnackbar,
    Toolbar,
    Hamburger,
    Drawer,
    DayFooter,
    CenterColumnWrapper,
    InvoiceRate,
  },

  data() {
    return {
      pageLoadTime: moment(),
    };
  },

  computed: {
    isBecomingActive(): boolean {
      const { oldState, newState } = this.interactionState;
      return oldState === "passive" && newState === "active";
    },

    thirtyMinutesSinceLastPageLoad(): boolean {
      return moment().diff(this.pageLoadTime, "minutes") > 30;
    },

    interactionState(): { oldState: string; newState: string } {
      return this.$store.state.interactionState;
    },

    userFound(): boolean {
      return this.$store.getters.isValidUser;
    },
  },

  watch: {
    interactionState() {
      if (
        isIPhone() &&
        this.isBecomingActive &&
        this.thirtyMinutesSinceLastPageLoad
      ) {
        location.reload();
      }
    },
  },

  methods: {
    openGapestokk() {
      this.$store.commit("SET_DONT_SHOW_GAPESTOKK", false);
    },
  },
});

function isIPhone() {
  return /iPhone/i.test(navigator.userAgent);
}
