












import Vue from "vue";
import moment, { Moment } from "moment";

export default Vue.extend({
  props: {
    date: {
      type: Object as () => Moment,
      default: {} as Moment,
    },
  },

  data() {
    return {
      hover: false,
    };
  },

  computed: {
    text(): string {
      const d = this.date.format("ddd D");
      const text = this.holiday && !this.hover ? this.holiday : d;
      const upperCased = text.charAt(0).toUpperCase() + text.slice(1);
      return upperCased;
    },

    holiday(): string {
      return this.$store.getters.getHoliday(this.date);
    },

    isDayOff(): boolean {
      return this.isSunday || this.isSaturday;
    },

    isHoliday(): boolean {
      return this.$store.getters.isHoliday(this.date);
    },

    isSunday(): boolean {
      return this.date.day() === 0;
    },

    isSaturday(): boolean {
      return this.date.day() === 6;
    },

    isToday(): boolean {
      return moment().isSame(this.date, "day");
    },
  },

  methods: {
    mouseOver() {
      this.hover = true;
    },

    mouseLeave() {
      this.hover = false;
    },
  },
});
