







import Vue from "vue";
import config from "@/config.ts";
import Tooltip from "@/components/Tooltip.vue";

export default Vue.extend({
  components: {
    Tooltip,
  },

  props: { tooltip: { type: String, default: "" } },
  methods: {
    onClick() {
      this.$emit("click");
      window.location.href = `slack://channel?team=${config.SLACK_TEAM_ID}&id=${config.SLACK_CHANNEL_ID}`;
    },
  },
});
