






























import Vue from "vue";
import { ErrorResponse } from "../services/httpClient";
import SlackButton from "@/components/SlackButton.vue";
import Tooltip from "@/components/Tooltip.vue";

declare global {
  interface Window {
    addError: any;
  }
}

export default Vue.extend({
  components: {
    SlackButton,
    Tooltip,
  },

  data() {
    return {
      duration: Infinity,
      position: "left",
    };
  },

  computed: {
    show: {
      get() {
        return !!this.$store.state.errorTexts.length;
      },
      set() {
        // :md-active.sync="show" neds a setter
      },
    },

    issues() {
      return this.$store.getters.getErrorMessages;
    },
    issueTitle() {
      const issues = this.$store.getters.getErrorMessages as ErrorResponse[];
      const lastIssue = issues[issues.length - 1];
      const issueCount = issues.length;
      if (lastIssue) {
        return issueCount > 1
          ? `${lastIssue.name} +${issueCount - 1} more error(s)`
          : lastIssue.name;
      }
      return "";
    },
    issueText() {
      const issues = this.$store.getters.getErrorMessages as ErrorResponse[];
      const lastIssue = issues[issues.length - 1];
      const issueCount = issues.length;
      if (lastIssue) {
        return issueCount > 1
          ? `${lastIssue.message} +${issueCount - 1} more error(s)`
          : lastIssue.message;
      }
      return "";
    },
  },

  created() {
    window.addError = (errorMessage: string) =>
      this.$store.commit("ADD_TO_ERROR_LIST", errorMessage);
  },

  methods: {
    onSlackClick() {
      this.$copyText(this.$store.getters.getAllErrors);
      this.close();
    },

    close() {
      this.$store.commit("CLEAR_ERROR_LIST");
    },
  },
});
