









































import Vue from "vue";
import YellowButton from "@/components/YellowButton.vue";
import authService from "../services/auth";
import config from "@/config";
import { AccountInfo } from "@azure/msal-common";

export default Vue.extend({
  components: {
    YellowButton,
  },

  data() {
    return {
      items: [
        { text: "Timeføring", routeName: "hours", icon: "query_builder" },
        { text: "Aktiviteter", routeName: "tasks", icon: "local_activity" },
        { text: "Statistikk", routeName: "summarizedhours", icon: "insights" },
        {
          text: "Overtid og ferie",
          routeName: "accumulated-hours",
          icon: "watch_later",
        },
        {
          text: "Personlige access token",
          routeName: "tokens",
          icon: "lock_open",
        },
      ],
    };
  },

  computed: {
    name(): string {
      return this.account && this.account.name ? this.account.name : "";
    },

    authText(): string {
      return this.account ? "Logg ut" : "Logg inn";
    },

    account(): AccountInfo {
      return this.$store.state.account;
    },
  },

  methods: {
    toggleMenu() {
      this.$store.commit("TOGGLE_DRAWER");
    },

    navTo(routeName: string) {
      this.$router.push(routeName);
      setTimeout(() => this.$store.commit("TOGGLE_DRAWER"), 150);
    },

    authAction() {
      if (this.account) {
        authService.logout();
      } else {
        authService.loginMsal();
      }
    },

    navToAdminpanel() {
      window.open(config.BASE_URL_ADMINPANEL);
    },
  },
});
