














































import Vue from "vue";
import moment from "moment";
import YellowButton from "./YellowButton.vue";
import config from "@/config";
import httpClient from "../services/httpClient";

interface Token {
  id: number;
  friendlyName: string;
  expiryDate: string;
}

export default Vue.extend({
  components: {
    YellowButton,
  },

  data() {
    return {
      tokens: [] as Token[],
      active: false,
      token: null as null | Token,
    };
  },

  computed: {
    prettyTokens(): Token[] {
      return this.tokens.map((token: Token) => {
        const { id, friendlyName, expiryDate } = token;
        const humanReadableDate = moment(expiryDate).format(
          "dddd D. MMMM YYYY"
        );
        return { id, friendlyName, expiryDate: humanReadableDate };
      });
    },
  },

  created() {
    this.fetchActiveAccessTokens();
  },

  methods: {
    isExpired(date: string) {
      const today = moment();
      return moment(date, "dddd D. MMMM YYYY").isBefore(today);
    },

    onDeleteClick(token: Token) {
      this.active = true;
      this.token = token;
    },

    onDeletAllClick() {
      this.active = true;
    },

    onConfirm() {
      if (this.token != null) {
        this.deleteAccessTokens([this.token]);
        this.token = null;
      } else {
        this.deleteAccessTokens(this.tokens);
      }
    },

    async fetchActiveAccessTokens() {
      httpClient
        .get<Token[]>(`${config.API_HOST}/api/user/ActiveAccessTokens`)
        .then(response => {
          this.tokens = response.data;
        });
    },

    async deleteAccessTokens(tokens: { id: number }[]) {
      httpClient
        .delete<{ id: number }[]>(`${config.API_HOST}/api/user/AccessToken`, {
          data: tokens.map(token => ({ tokenId: token.id })),
        })
        .then(response => {
          this.tokens = this.tokens.filter(
            (token: Token) =>
              !response.data.some(deletedToken => deletedToken.id === token.id)
          );
        });
    },
  },
});
