








import Vue from "vue";
export default Vue.extend({
  props: {
    holiday: {
      type: String,
      default: "",
    },
  },
});
