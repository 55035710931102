











import Vue from "vue";
export default Vue.extend({
  props: {
    value: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    error: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "text",
    },
  },

  methods: {
    update() {
      this.$emit("input", (this.$refs.inputRef as HTMLInputElement).value);
    },
  },
});
