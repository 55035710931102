





























import Vue from "vue";
import { Task } from "@/store/tasks";
import TimeEntrieText from "./TimeEntrieText.vue";
import Input from "./Input.vue";
import YellowButton from "./YellowButton.vue";
import CenterColumnWrapper from "@/components/CenterColumnWrapper.vue";
import Fuse from "fuse.js";

export default Vue.extend({
  components: {
    TimeEntrieText,
    CenterColumnWrapper,
    Input,
    YellowButton,
  },

  data() {
    return {
      searchphrase: "",
      selectedEntryKey: -1,
    };
  },

  computed: {
    tasks(): Task[] {
      if (this.searchphrase.length === 0) {
        return this.$store.state.tasks;
      }
      const fuse = new Fuse<Task>(this.$store.state.tasks, {
        keys: ["name", "project.name", "project.customer.name"],
        fieldNormWeight: 0,
      });
      const result = fuse.search(this.searchphrase);
      return result.map((x: { item: Task }) => x.item);
    },

    isOnline() {
      return this.$store.state.isOnline;
    },
  },
  created() {
    this.$store.dispatch("FETCH_TASKS");
  },
  methods: {
    clear() {
      this.searchphrase = "";
    },
    toggleFavorite(task: Task) {
      this.$store.dispatch("PUSH_TASKS", [
        { ...task, favorite: !task.favorite },
      ]);
    },
    onExpandEntry(id: number) {
      // If selected entry is same as currently selected, reset to close entry
      if (this.selectedEntryKey === id) {
        this.selectedEntryKey = -1;
      } else {
        this.selectedEntryKey = id;
      }
    },
  },
});
