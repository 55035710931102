
























import Vue from "vue";
import TimeEntrieWeek from "./TimeEntrieWeek.vue";
import TimeEntrieText from "./TimeEntrieText.vue";
import DayPill from "./DayPill.vue";
import ZeroSelectedTasks from "./ZeroSelectedTasks.vue";
import { Task } from "@/store/tasks";
import { FrontendTimentrie } from "@/store/timeEntries";
import config from "@/config";
import { Moment } from "moment";
import CenterColumnWrapper from "@/components/CenterColumnWrapper.vue";

export default Vue.extend({
  components: {
    TimeEntrieWeek,
    TimeEntrieText,
    DayPill,
    CenterColumnWrapper,
    ZeroSelectedTasks,
  },
  props: { week: { type: Array as () => Moment[], default: () => [] } },
  data() {
    return {
      selectedEntryKey: -1,
    };
  },
  computed: {
    tasks(): Task[] {
      const rows = [...this.tasksWithHours, ...this.tasksWithoutHours].sort(
        sortList
      );
      if (rows.length <= 4) {
        return rows;
      }
      const activeDate = this.$store.state.activeDate.format(
        config.DATE_FORMAT
      );
      const activeDateIsInWeek = this.week.some(
        (date: Moment) => date.format(config.DATE_FORMAT) === activeDate
      );
      return activeDateIsInWeek ? rows : rows.slice(0, 3);
    },

    tasksWithHours(): Task[] {
      const tasks: Task[] = [];
      for (const entrie of this.weeksTimeEntries) {
        const task = this.$store.getters.getTask(entrie.taskId);
        if (!tasks.some(t => t.id === task.id)) {
          tasks.push(task);
        }
      }
      return tasks;
    },

    tasksWithoutHours(): Task[] {
      return this.$store.getters.favoriteTasks.filter(
        (task: Task) => !this.tasksWithHours.some(t => t.id === task.id)
      );
    },

    weeksTimeEntries(): FrontendTimentrie[] {
      const timeEntries = this.$store.state.timeEntries
        ? this.$store.state.timeEntries
        : [];
      return timeEntries.filter((entrie: FrontendTimentrie) =>
        this.isThisWeek(entrie.date)
      );
    },

    daysOfWeek(): string[] {
      return this.week.map((day: Moment) => {
        const d = day.format("ddd DD");
        return d.charAt(0).toUpperCase() + d.slice(1);
      });
    },

    weekNumber(): number {
      return this.week[0].week();
    },
  },

  methods: {
    isThisWeek(d: string): boolean {
      return this.week.some(
        (date: Moment) => date.format(config.DATE_FORMAT) === d
      );
    },
    onExpandEntry(id: number) {
      // If selected entry is same as currently selected, reset to close entry
      if (this.selectedEntryKey === id) {
        this.selectedEntryKey = -1;
      } else {
        this.selectedEntryKey = id;
      }
    },
  },
});

export function sortList(a: Task, b: Task) {
  if (a.project.customer.name > b.project.customer.name) {
    return 1;
  } else if (a.project.customer.name < b.project.customer.name) {
    return -1;
  }

  if (a.name > b.name) {
    return 1;
  } else if (a.name < b.name) {
    return -1;
  } else {
    return 0;
  }
}
