











import Vue from "vue";
import authService from "../services/auth";
import Progress from "@/components/Progress.vue";

export default Vue.extend({
  components: {
    Progress,
  },

  computed: {
    progressBarVisible() {
      return !this.$store.state.tasks.length && !!this.$store.state.account;
    },
  },

  methods: {
    login: () => authService.loginMsal(),
  },
});
