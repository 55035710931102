










import Vue from "vue";
import YellowButton from "./YellowButton.vue";
import authService from "../services/auth";

export default Vue.extend({
  components: {
    YellowButton,
  },

  computed: {
    userName(): string {
      const account = this.$store.state.account;
      return account ? account.userName : "";
    },

    label(): string {
      const userName = this.userName ? this.userName : "Denne AzureAd brukeren";
      return userName + " har ikke tilgang til Alvtime";
    },
  },

  methods: {
    logout() {
      authService.logout();
    },
  },
});
