











import Vue from "vue";
import NavButtons from "@/components/NavButtons.vue";
import Title from "@/components/Title.vue";
import HourSums from "@/components/HourSums.vue";
import AvailableHours from "@/components/AvailableHours.vue";

export default Vue.extend({
  components: {
    NavButtons,
    Title,
    HourSums,
    AvailableHours,
  },

  computed: {
    userFound(): boolean {
      return this.$store.getters.isValidUser;
    },
  },
});
