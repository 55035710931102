















import Vue from "vue";
import YellowButton from "./YellowButton.vue";

export default Vue.extend({
  components: {
    YellowButton,
  },

  methods: {
    navToTasks() {
      this.$router.push("tasks");
    },
  },
});
