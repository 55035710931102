






import Vue from "vue";
import YellowButton from "./YellowButton.vue";
export default Vue.extend({
  components: {
    YellowButton,
  },

  computed: {
    mobile(): boolean {
      // @ts-ignore
      return this.$mq == "sm";
    },
  },

  methods: {
    toggleMenu() {
      this.$store.commit("TOGGLE_DRAWER");
    },
  },
});
