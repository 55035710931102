















import Vue from "vue";
import Tooltip from "@/components/Tooltip.vue";

export default Vue.extend({
  components: {
    Tooltip,
  },

  data() {
    return {
      duration: Infinity,
      position: "left",
      refreshing: false,
      registration: null as any,
      updateExists: false,
    };
  },

  created() {
    document.addEventListener("swUpdated", this.showRefreshUI, { once: true });
    navigator.serviceWorker.addEventListener("controllerchange", () => {
      if (this.refreshing) return;
      this.refreshing = true;
      window.location.reload();
    });
  },

  methods: {
    showRefreshUI(e: any) {
      this.registration = e.detail;
      this.updateExists = true;
    },

    refreshApp() {
      this.updateExists = false;
      if (!!this.registration && !!this.registration.waiting) {
        this.registration.waiting.postMessage("skipWaiting");
      }
    },
  },
});
