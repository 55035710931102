















import Vue from "vue";
import Tooltip from "@/components/Tooltip.vue";

export default Vue.extend({
  components: {
    Tooltip,
  },

  data() {
    return {
      duration: Infinity,
      position: "left",
      show: false,
    };
  },

  computed: {
    isOnline(): boolean {
      return !!this.$store.state.isOnline;
    },
  },

  watch: {
    isOnline() {
      this.show = !this.isOnline;
    },
  },

  created() {
    this.$store.commit("UPDATE_ONLINE_STATUS");
    window.addEventListener("online", () =>
      this.$store.commit("UPDATE_ONLINE_STATUS")
    );
    window.addEventListener("offline", () =>
      this.$store.commit("UPDATE_ONLINE_STATUS")
    );
  },

  methods: {
    close() {
      this.show = false;
    },
  },
});
