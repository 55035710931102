















import Vue from "vue";

export default Vue.extend({
  props: {
    initialComment: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    console.log(this.isOverflowing);

    if (this.isOverflowing) {
      (this.$refs.box as HTMLDivElement).classList.add("overflow");
    }
  },
  data() {
    return {
      comment: this.initialComment,
    };
  },
  watch: {
    initialComment(newComment: string) {
      this.comment = newComment;
    },
  },
  computed: {
    isOverflowing(): boolean {
      const rect = (this.$refs.box as HTMLDivElement)?.getBoundingClientRect();
      if (rect == undefined) return false;

      const windowHeight = window.innerHeight;
      return rect.bottom > windowHeight;
    },
  },
  methods: {
    onClickOutside() {
      this.$emit("close");
    },
    onChange() {
      this.$emit("change", this.comment);
    },
  },
});
